<template>
  <payouts-layout>
    <div class="d-flex align-items-center">
      <b-form-group :label="translations.filters.deal" class="flex-grow-1 mr-3" style="max-width: 500px;">
        <b-form-input v-model="filters.deal_name_contains"/>
      </b-form-group>
      <b-form-group :label="translations.filters.rvp" class="flex-grow-1 mr-3" style="max-width: 500px;">
        <b-form-select
          v-model="filters.rvp_uuid"
          :disabled="loading"
          :options="rvpOptions"/>
      </b-form-group>
      <b-form-group :label="translations.filters.payable_date_range" class="flex-grow-1 mr-3" style="max-width: 500px;">
        <vue-ctk-date-time-picker
          format="YYYY-MM-DD"
          data-testid="payable-date-range-datepicker"
          id="payable-date-range-datepicker"
          :label="translations.filters.date_range_placeholder"
          formatted="ll"
          range
          only-date
          :error="!dateRangeValidator(filters.payable_date_range)"
          v-model="filters.payable_date_range"/>
      </b-form-group>
      <b-form-group :label="translations.filters.transaction_date_range" class="flex-grow-1 mr-3" style="max-width: 500px;">
        <vue-ctk-date-time-picker
          format="YYYY-MM-DD"
          data-testid="transaction-date-range-datepicker"
          id="transaction-date-range-datepicker"
          :label="translations.filters.date_range_placeholder"
          formatted="ll"
          range
          only-date
          :error="!dateRangeValidator(filters.transaction_date_range)"
          v-model="filters.transaction_date_range"/>
      </b-form-group>
      <b-button
        data-testid="search-button"
        variant="primary"
        class="mt-2"
        @click="handleSearch"
        :disabled="loading">
        {{ translations.filters.search }}
      </b-button>
      <b-dropdown
        data-testid="export-transactions-dropdown"
        class="mt-2 ml-4"
        :text="translations.btn_export"
        :disabled="loading">
        <b-dropdown-item data-testid="export-transactions-both" @click="() => this.handleExport('both')">
          <feather type="download"></feather> {{ translations.btn_export_both }}
        </b-dropdown-item>
        <b-dropdown-item data-testid="export-transactions-revenue" @click="() => this.handleExport('revenue')">
          <feather type="download"></feather> {{ translations.btn_export_revenue }}
        </b-dropdown-item>
        <b-dropdown-item data-testid="export-transactions-booking" @click="() => this.handleExport('booking')">
          <feather type="download"></feather> {{ translations.btn_export_booking }}
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <div class="w-100">
      <b-tabs content-class="mt-3">
        <b-tab
        v-for="(tab, index) in tabs"
        :key="tab.key"
        :title="tab.title"
        :active="selectedTab === index"
        @click="selectTab(index)">
          <b-container fluid>
            <component
            v-if="selectedTab === index"
            :is="tab.component"
            :ref="tab.key"
            @mounted="onChildMounted"/>
          </b-container>
        </b-tab>
      </b-tabs>
    </div>
  </payouts-layout>
</template>

<script>
import { cloneDeep } from 'lodash';
import moment from 'moment';
import utils from '@/scripts/tools/utils';
import translations from '@/translations';
import { parseResponseError } from '@/http/parsers/parse_response';
import service from '@/services/commission';
import PayoutsLayout from '@/components/Commissions/PayoutsLayout.vue';
import RevenueList from './revenueList.vue';
import BookingList from './bookingList.vue';
import PendingBookingList from './pendingBookingList.vue';

export default {
  components: {
    PayoutsLayout,
    RevenueList,
    BookingList,
    PendingBookingList,
  },
  name: 'Transactions',
  data() {
    return {
      translations: translations.commissions.transactions,
      filters: {
        payable_date_range: null,
        transaction_date_range: null,
        rvp_uuid: '',
        deal_name_contains: null,
      },
      persistFilters: {},
      loading: false,
      selectedTab: 0,
      tabs: [
        {
          key: 'revenue',
          title: translations.commissions.transactions.tabs.revenue,
          component: 'RevenueList',
        },
        {
          key: 'booking',
          title: translations.commissions.transactions.tabs.booking,
          component: 'BookingList',
        },
        {
          key: 'pending_booking',
          title: translations.commissions.transactions.tabs.pending_booking,
          component: 'PendingBookingList',
        },
      ],
      rvpOptions: [
        { value: '', text: translations.commissions.transactions.filters.default_rvp_filter },
      ],
    };
  },
  async beforeMount() {
    // persist selected tab
    const tab = this.$route.query?.tab || 'revenue';
    const tabIndex = this.tabs.findIndex(t => t.key === tab);
    if (tabIndex !== -1) this.selectedTab = tabIndex;
    await this.loadRvps();
  },
  methods: {
    selectTab(index) {
      this.selectedTab = index;

      this.sendFiltersToChildren();

      // reset query params when changing tabs
      const currentRoute = this.$route;
      this.$router.replace({
        path: currentRoute.path,
        query: {
          tab: this.tabs[index].key,
        },
      }).catch(() => {});
    },
    handleSearch() {
      this.persistFilters = cloneDeep(this.filters);

      if (this.filters.payable_date_range) {
        const { start, end } = this.persistFilters.payable_date_range;
        this.persistFilters.payable_date_start = start;
        this.persistFilters.payable_date_end = end;
      }
      delete this.persistFilters.payable_date_range;

      if (this.filters.transaction_date_range) {
        const { start, end } = this.persistFilters.transaction_date_range;
        this.persistFilters.transaction_date_start = start;
        this.persistFilters.transaction_date_end = end;
      }
      delete this.persistFilters.transaction_date_range;

      this.sendFiltersToChildren();
    },
    onChildMounted() {
      this.sendFiltersToChildren();
    },
    sendFiltersToChildren() {
      const children = this.$refs[this.tabs[this.selectedTab].key];
      if (children?.length) children[0].applyFilters(this.persistFilters, 1);
    },
    dateRangeValidator(field) {
      return !field || (field.start && field.end);
    },
    async handleExport(option) {
      if (this.loading) return;
      this.loading = true;

      const dateFormatted = moment().utc().format('YYYYMMDD_HHmmss');
      let fileName = `commission_transactions_list_${dateFormatted}.csv`;
      const exportFilters = cloneDeep(this.persistFilters);

      switch (option) {
        case 'revenue':
          exportFilters.view = 'revenue';
          fileName = `commission_revenue_transactions_list_${dateFormatted}.csv`;
          break;
        case 'booking':
          exportFilters.view = 'booking';
          fileName = `commission_booking_transactions_list_${dateFormatted}.csv`;
          break;
        default:
          break;
      }

      try {
        const buf = await service.exportTransactions(exportFilters);
        utils.downloadFile(buf, fileName);
      } catch (err) {
        this.$noty.error(`${this.translations.errors.export_csv}: ${parseResponseError(err)}`);
      } finally {
        this.loading = false;
      }
    },
    async loadRvps() {
      this.loading = true;
      try {
        const data = await service.getRVPs({
          order_by: 'name',
        });

        const responseFilters = data.rvps.map(rvp => ({ value: rvp.uuid, text: rvp.name })) ?? [];
        this.rvpOptions = [ ...this.rvpOptions, ...responseFilters ];
      } catch (err) {
        this.$noty.error(this.translations.errors.loading_rvps);
      }
      this.loading = false;
    },
  },
};
</script>
