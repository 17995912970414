<template>
  <button
    type="button"
    class="btn"
    :class="className"
    @click="confirm(doRequest)">
      {{ text }}
    </button>
</template>

<script>
import { parseResponseError } from '@/http/parsers/parse_response';
import service from '@/services/commission';

export default {
  name: 'ApproveButton',
  props: {
    transactionId: {
      type: Number,
      required: true,
    },
    className: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
    modalConfirmMessage: {
      type: String,
      required: true,
    },
    errorApproveMessage: {
      type: String,
      required: true,
    },
    successApproveMessage: {
      type: String,
      required: true,
    },
  },
  methods: {
    confirm(callback) {
      this.$bvModal.msgBoxConfirm(this.modalConfirmMessage)
        .then(value => {
          if (!value) {
            return;
          }
          callback();
        });
    },
    async doRequest() {
      try {
        await service.approveTransaction(this.transactionId);
        this.$noty.success(`${this.successApproveMessage}: ID ${this.transactionId}`);
        this.$emit('approved');
      } catch (err) {
        this.$noty.error(`${this.errorApproveMessage}: ${parseResponseError(err)}`);
      }
    },
  },
};
</script>
