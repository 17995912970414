<template>
  <financial-list
  ref="payouts-booking-transactions"
  v-model="currentPage"
  :header-data="tableHeaders"
  :data="transactions"
  :disabled="loading"
  :items-per-page="itemsPerPage"
  :total="total"
  :no-items-message="translations.table.empty"
  itemRefKey="id"
  @page-changed="fetchTransactions"/>
</template>

<script>
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import { parseResponseError } from '@/http/parsers/parse_response';
import service from '@/services/commission';
import translations from '@/translations';
import { formatDate } from '@/helpers/finance';

export default {
  name: 'BookingList',
  components: {
    FinancialList,
  },
  data() {
    return {
      translations: translations.commissions.transactions,
      loading: false,
      appliedFilters: {},
      transactions: [],
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
      transactionTypeShort: {
        COMMISSION_BOOKING_ASO: 'ASO',
        COMMISSION_BOOKING_NON_ASO: 'NON-ASO',
      },
    };
  },
  computed: {
    tableHeaders() {
      return [
        associateHeaderDataItem(this.translations.table.id, 'id'),
        associateHeaderDataItem(this.translations.table.deal, 'deal_name'),
        associateHeaderDataItem(this.translations.table.opportunity_type, 'opportunity_type'),
        associateHeaderDataItem(this.translations.table.channel_deal, 'original_channel_deal_name'),
        associateHeaderDataItem(this.translations.table.deal_product, 'opportunity_product_code'),
        associateHeaderDataItem(this.translations.table.close_date, 'deal_close_date'),
        associateHeaderDataItem(this.translations.table.rvp_name, 'rvp_name'),
        associateHeaderDataItem(this.translations.table.carr, 'deal_product_carr'),
        associateHeaderDataItem(this.translations.table.rvp_rate, 'rvp_plan_revenue_percentage'),
        associateHeaderDataItem(this.translations.table.transaction_type, 'transaction_type'),
        associateHeaderDataItem(this.translations.table.amount, 'transaction_value'),
        associateHeaderDataItem(this.translations.table.payable_date, 'payable_date'),
      ];
    },
  },
  mounted() {
    this.$emit('mounted');
  },
  methods: {
    async applyFilters(filters, page = 1) {
      this.currentPage = page;
      this.appliedFilters = filters;

      await this.fetchTransactions(this.currentPage);
    },
    async fetchTransactions(page) {
      this.loading = true;
      this.currentPage = page;

      const offset = (page - 1) * this.itemsPerPage;
      const limit = this.itemsPerPage;

      try {
        const { transactions, total } = await service.getTransactions({
          ...this.appliedFilters,
          view: 'booking',
          order_by: 'id',
          order_descending: true,
          offset,
          limit,
        });

        this.transactions = transactions;
        this.transactions.forEach(t => {
          t.deal_name = this.splitName(t.deal_name);
          t.original_channel_deal_name = t.original_channel_deal_name ? this.splitName(t.original_channel_deal_name) : '(direct)';
          t.payable_date = formatDate(t.payable_date);
          t.deal_close_date = formatDate(t.deal_close_date);
          const transactionType = this.transactionTypeShort[t.transaction_type];
          t.transaction_type = transactionType;
          t.deal_product_carr = transactionType === 'NON-ASO' ? t.deal_product_carr : '';
          t.rvp_plan_revenue_percentage = transactionType === 'NON-ASO' ? t.rvp_plan_revenue_percentage : '';
        });
        this.total = total;
      } catch (err) {
        this.$noty.error(`${this.translations.errors.fetch_list}: ${parseResponseError(err)}`);
      } finally {
        this.loading = false;
      }
    },
    splitName(name) {
      if (!name) return '-';
      return name.split('|')[0];
    },
  },
};
</script>
